import React from 'react';

function EpisodeDescriptionsBlock({ selectedEpisodeDetails }) {
  return (
    <>
      {selectedEpisodeDetails !== null && (
        <div>
          {selectedEpisodeDetails === "00" ? (
            <p>alpha</p>
          ) : selectedEpisodeDetails === "default" ? (
            <p>default</p>
          ) : (
            <p>No matching episode</p>
          )}
        </div>
      )}
    </>
  );
}

export default EpisodeDescriptionsBlock;